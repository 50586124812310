const config = (token) => {
    return {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
};

module.exports = {
    // prod - "https://imp-dev.inno.ws"
    // dev - "http://localhost:8084"
    SERVER_URL: "https://imp.inno.ws",

    // dev - "https://hrm-dev.inno.ws/auth"
    // stage - "https://hrm-stage.inno.ws/auth"
    // prod - "https://keycloak.innowise-group.com/auth"
    KEYCLOAK_INIT_URL: "https://hrm.inno.ws/auth",
    KEYCLOAK_INIT_REALM: "innowise-group",
    KEYCLOAK_INIT_CLIENT_ID: "innowise-group",
    KEYCLOAK_INIT_ON_LOAD: "login-required",

    // local - "http://localhost:3000/"
    // dev - "https://imp-dev.inno.ws/"
    // prod - "https://imp.innowise-group.com/"
    REDIRECT_AFTER_LOGOUT_URL: "https://imp.inno.ws/",

    AVATAR_URL:
        "https://png.pngtree.com/png-vector/20191103/ourlarge/pngtree-handsome-young-guy-avatar-cartoon-style-png-image_1947775.jpg",

    DATA_PROVIDER_URL: "/api/v1",

    registerValidToken: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/auth";

        fetch(url, config(token));
    },

    refreshToken: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/auth/refresh";

        fetch(url, config(token));
    },

    registerBlockToken: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/logout";

        fetch(url, config(token));
    },

    TAG: [
        {id: "ENTERTAINMENT", name: "Развлечения"},
        {id: "MANAGER_DEVELOPMENT", name: "Развитие менеджеров"},
        {id: "SOCIAL_ACTIVITY", name: "Социальная активность"},
        {id: "SPORTS", name: "Спорт"},
        {id: "TECHNOLOGY_DEVELOPMENT", name: "Технологическое развитие"},
    ],

    EVENT_FORMAT: [
        {id: "ONLINE", name: "Онлайн"},
        {id: "OFFLINE", name: "Оффлайн"},
    ],

    FEEDBACK_CATEGORY: [
        {id: "COMPLAINT", name: "Жалоба"},
        {id: "SUGGESTION", name: "Предложение"},
    ],

    NEWS_STATUS: [
        {id: "DRAFT", name: "Черновик"},
        {id: "PUBLISHED", name: "Опубликована"},
    ],

    IS_SUPPORT: [
        {id: "true", name: "Поддерживается"},
        {id: "false", name: "Не поддерживается"},
    ],

    MONTHS: [
        {id: null, name: "Не выбирать"},
        {id: 1, name: "Январь"},
        {id: 2, name: "Февраль"},
        {id: 3, name: "Март"},
        {id: 4, name: "Апрель"},
        {id: 5, name: "Май"},
        {id: 6, name: "Июнь"},
        {id: 7, name: "Июль"},
        {id: 8, name: "Август"},
        {id: 9, name: "Сентябрь"},
        {id: 10, name: "Октябрь"},
        {id: 11, name: "Ноябрь"},
        {id: 12, name: "Декабрь"}
    ],

    getAllBonusCities: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/data-management/bonus-cities/cities";

        let bonusCities = [];
        let filledBonusCities = [];

        fetch(url, config(token))
            .then((res) => res.json())
            .then((data) => (bonusCities = data))
            .then(() => {
                bonusCities.map((bonusCity) => {
                    let helpStatus = {
                        id: bonusCity.id,
                        name: bonusCity.city,
                    };
                    filledBonusCities.push(helpStatus);
                });
            });

        return filledBonusCities;
    },

    getAllBonusCitiesForCreate: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/data-management/bonus-cities/cities";

        let bonusCities = [];
        let filledBonusCities = [];

        fetch(url, config(token))
            .then((res) => res.json())
            .then((data) => (bonusCities = data))
            .then(() => {
                bonusCities.map((bonusCity) => {
                    let helpStatus = {
                        id: bonusCity.id,
                        name: bonusCity.city,
                    };
                    filledBonusCities.push(helpStatus);
                });
            });
        filledBonusCities.push({id: 0, name: 'Все города'})
        return filledBonusCities;
    },

    getAllBonusCategories: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/data-management/bonus-categories/categories";

        let bonusCategories = [];
        let filledBonusCategories = [];

        fetch(url, config(token))
            .then((res) => res.json())
            .then((data) => (bonusCategories = data))
            .then(() => {
                bonusCategories.map((bonusCategory) => {
                    let helpStatus = {
                        id: bonusCategory.id,
                        name: bonusCategory.name,
                    };
                    filledBonusCategories.push(helpStatus);
                });
            });

        return filledBonusCategories;
    },

    getAllHelpStatus: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/data-management/help-cards/help-statuses";

        let helpStatuses = [];
        let filledHelpStatuses = [];

        fetch(url, config(token))
            .then((res) => res.json())
            .then((data) => (helpStatuses = data))
            .then(() => {
                helpStatuses.map((help) => {
                    let helpStatus = {
                        id: help.helpCardId,
                        name: help.name,
                    };
                    filledHelpStatuses.push(helpStatus);
                });
            });

        return filledHelpStatuses;
    },

    getAllCities: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/notifications/locations";

        let locations = [];
        let cities = [];

        fetch(url, config(token))
            .then((res) => res.json())
            .then((data) => (locations = data))
            .then(() => {
                locations.map((loc) => {
                    let town = {
                        id: loc.townCode,
                        name: loc.town,
                    };
                    if (cities.find((c) => c.id === town.id) === undefined) {
                        cities.push(town);
                    }
                });
            });

        return cities;
    },

    getAllOffices: (token) => {
        const url = module.exports.DATA_PROVIDER_URL + "/notifications/locations";

        let locations = [];
        let offices = [];

        fetch(url, config(token))
            .then((res) => res.json())
            .then((data) => (locations = data))
            .then(() => {
                locations.map((loc) => {
                    let town = {
                        id: loc.officeCode,
                        name: loc.office,
                    };
                    offices.push(town);
                });
            });

        return offices;
    },
};

